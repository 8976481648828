<template>
  <div>
    <v-tabs
      grow
      height="58"
      background-color="light-green"
      hide-slider
    >
      <v-tab active-class="color: primary">
        <span class="tw-text-white tw-font-bold">Formulários</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tw-text-white tw-font-bold">Questões</span>
      </v-tab>
      <v-tab-item>
        <form-list />
      </v-tab-item>
      <v-tab-item>
        <question-list />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import FormList from './FormList'
import QuestionList from './QuestionList'

export default {
  name: 'AgendaSettings',
  components: {
    FormList,
    QuestionList,
  },
}
</script>
