<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
      <div class="tw-grid tw-gap-4 md:tw-grid-cols-4">
        <v-text-field
          v-model="filters.label"
          filled
          label="Buscar por título"
          clearable
          no-data-text="Digite para buscar"
          hide-details
          @change="listQuestionResults"
          @click:clear="listQuestionResults"
        />
        <v-text-field
          v-model="filters.identifier"
          filled
          label="Buscar por código"
          clearable
          no-data-text="Digite para buscar"
          hide-details
          @change="listQuestionResults"
          @click:clear="listQuestionResults"
        />
        <v-autocomplete
          v-model="filters.categoryId"
          :items="categories"
          label="Categoria"
          item-text="name"
          item-value="id"
          :no-data-text="notFoundMessage"
          clearable
          filled
          hide-details
          @change="listQuestionResults"
          @click:clear="listQuestionResults"
        />
        <v-btn
          class="btn-filter--height tw-font-semibold"
          color="dark-green"
          dark
          block
          large
          @click="listQuestionResults"
        >
          <v-icon left>
            mdi-magnify
          </v-icon>
          <span class="tw-font-semibold">
            Buscar Questão
          </span>
        </v-btn>
      </div>
    </div>

    <div class="tw-px-12 tw-mt-4">
      <div class="tw-flex tw-justify-center tw-mb-4">
        <v-btn
          class="tw-w-1/3"
          color="primary"
          large
          @click="showCreateQuestionDialog = true"
        >
          <span class="tw-font-bold">
            Nova Questão
          </span>
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="questions"
        hide-default-footer
        disable-pagination
        class="table--striped"
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
      >
        <template v-slot:item="question">
          <tr>
            <td>{{ question.item.label }}</td>
            <td>{{ question.item.identifier }}</td>
            <td>{{ question.item.category.name }}</td>
            <td>{{ question.item.type }}</td>
            <td>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="tw-mr-3"
                    text
                    icon
                    @click="viewQuestionDialog(question.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Pergunta</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="tw-mr-3"
                    text
                    icon
                    @click="editDialog(question.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="tw-mr-3"
                    text
                    icon
                    v-on="on"
                    @click="deleteDialog(question.item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <create-question
      v-if="showEditQuestionDialog"
      :show-dialog="showEditQuestionDialog"
      edit-question
      :question-data="editedQuestion"
      @closeDialog="closeEditDialog"
    />

    <create-question
      v-if="showCreateQuestionDialog"
      :show-dialog="showCreateQuestionDialog"
      @closeDialog="showCreateQuestionDialog = false"
    />

    <v-dialog
      v-model="showViewQuestionDialog"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="card-dialog__title font-weight-lightbold">
          Visualizar Questão
          <v-spacer />
          <v-btn
            icon
            @click="exitViewQuestionDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="tw-mb-4" />
        <v-card-text>
          <question-builder
            v-if="showViewQuestionDialog"
            :question="viewQuestion"
            preview
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDeleteQuestionDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-text>
          <div class="tw-text-base tw-font-bold tw-pt-4">
            Deseja realmente excluir essa questão?
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-2 tw-pb-2">
            <v-btn
              color="green darken-1"
              text
              large
              @click="exitDeleteDialog"
            >
              <span class="tw-font-semibold">
                Cancelar
              </span>
            </v-btn>
            <v-btn
              large
              color="error"
              @click="handleDeleteQuestion"
            >
              <span class="tw-font-semibold">
                Remover
              </span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
  isNil,
  reject,
  either,
  cond,
  equals,
  always,
  T,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'QuestionList',
  components: {
    CreateQuestion: () => import('./CreateQuestion'),
    QuestionBuilder: () => import('@/components/careFields/QuestionBuilder'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      showEditQuestionDialog: false,
      showCreateQuestionDialog: false,
      showViewQuestionDialog: false,
      showDeleteQuestionDialog: false,
      editedQuestion: {},
      deletedQuestion: {},
      viewQuestion: {},
      filters: {},
      listLoaded: true,
      count: '',
      numberOfPages: null,
      page: 1,
      headers: [
        {
          text: 'Nome',
          value: 'label',
          width: '40%',
        },
        {
          text: 'Código',
          value: 'identifier',
          width: '20%',
        },
        {
          text: 'Categoria',
          value: 'category',
          width: '10%',
        },
        {
          text: 'Tipo',
          value: 'type',
          width: '10%',
        },
        {
          text: 'Ações',
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      categories: 'careForm/categories',
      questions: 'careForm/questions',
    }),
    isQuestionListEmpty() {
      return isEmpty(this.questions)
    },
  },
  mounted() {
    this.listQuestionResults()
  },
  methods: {
    ...mapActions({
      listForm: 'careForm/listForm',
      listQuestion: 'careForm/listQuestion',
      deleteQuestion: 'careForm/deleteQuestion',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async handleDeleteQuestion() {
      try {
        await this.deleteQuestion(this.deletedQuestion.id)
        this.buildSnackbar('success', 'Questão deletada com sucesso!')
        this.isSaving = false
      } catch ({ response }) {
        this.handleError(response)
      }
      this.deletedQuestion = {}
      this.showDeleteQuestionDialog = false
      await this.listQuestion()
    },
    handleError(error) {
      const errorMessage = cond([
        [equals('question_delete_invalid'), always('A questão não pode ser excluida, pois está atrelada a um formulário')],
        [T, always('Erro interno')],
      ])(error.data.errorCode)

      this.buildSnackbar('error', errorMessage)
    },
    listQuestionResults() {
      this.$nextTick(async () => {
        const params = reject(either(isNil, isEmpty), this.filters)
        try {
          await this.listQuestion(params)
          this.listLoaded = false
        } catch (e) {
          this.buildSnackbar('error', 'Erro Interno!')
        }
      })
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    editDialog(questionData) {
      this.editedQuestion = questionData
      this.showEditQuestionDialog = true
    },
    closeEditDialog() {
      this.editedQuestion = {}
      this.showEditQuestionDialog = false
      this.listForm()
    },
    viewQuestionDialog(question) {
      this.viewQuestion = question
      this.showViewQuestionDialog = true
    },
    exitViewQuestionDialog() {
      this.viewQuestion = {}
      this.showViewQuestionDialog = false
    },
    deleteDialog(question) {
      this.deletedQuestion = question
      this.showDeleteQuestionDialog = true
    },
    exitDeleteDialog() {
      this.deletedQuestion = {}
      this.showDeleteQuestionDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.v-btn.v-btn--text
  border solid 1px thin-gray
</style>
