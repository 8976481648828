import router from '@/router'
import axios from '@/lib/axios'
import moment from 'moment'
import {
  always,
  any,
  clone,
  concat,
  cond,
  equals,
  find,
  findIndex,
  includes,
  isNil,
  is,
  path,
  hasPath,
  propEq,
  values,
  pluck,
  groupBy,
  map,
  minBy,
  prop,
  reduce,
  reject,
  sort,
  T,
  ascend,
  not,
  filter,
  pathEq,
} from 'ramda'
import isNilOrEmpty from '@/utils/dataValidators'
import {
  CARE_LINE,
  EMR_QUESTIONS,
  ENCOUNTER,
  HMO,
  SPECIALITY,
} from 'amparo-enums'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import structRequiredFunctions from '@/utils/structRequiredFunctions'

const { questions } = EMR_QUESTIONS

const runMultipleFunctions = functions => (parameters) => {
  for (const func of functions) {
    const res = structRequiredFunctions[func](parameters)
    if (res) return res
  }

  return null
}

const getRequiredFunction = (question) => {
  if (is(String, question.required)) {
    return structRequiredFunctions[question.required]
  }
  if (is(Array, question.required)) {
    return runMultipleFunctions(question.required)
  }
  return always(null)
}

const initialState = {
  emrAllowedProfessions: [
    'profession_cjz3an5zj00020nxbgcea9qh5',
    'profession_cjz3an5zk00030nxbejbsg6wj',
    'profession_cjz3an5zk00040nxbf1ple8ju',
    'profession_cjzbz1g7t000gb0vo907k3kv3',
    'profession_cjzbz9pgk000hb0vogejnajuo',
    'profession_ck55qhfkr0000a4pq4k9g4f7r',
    'profession_ckia97ivw0003igsz1cxg71gf',
    'profession_clhp735y3000057qlgsd1193x',
  ],
  NotFoundMessage: {
    version: 'Versão não encontrada',
    code: 'Código não encontrado',
    material: 'Material não encontrado',
  },
  allEncounters: [],
  attachments: [],
  chartsURI: [],
  devolvedCondition: null,
  encounter: {},
  encounterAppointmentOptions: {},
  encounterReasonId: questions.encounterReason,
  encounters: [],
  encountersFilters: {},
  encountersPagination: {},
  encounterTime: null,
  encounterFormTouched: false,
  evaluationAdditionalInformationId: questions.evaluationAdditionalInformation,
  evaluationId: questions.conditions,
  height: '',
  historyConditionsId: questions.historyConditions,
  isLoadingSpecificEncounter: false,
  isNewEncounter: false,
  medicalReferralId: questions.medicalReferral,
  patient: {},
  prescriptionsId: questions.treatments,
  questions,
  recycledCondition: null,
  recycledConditionToRemove: null,
  refreshTime: 15000,
  reload: 0,
  reminderEncounterId: questions.reminders,
  requestExamsId: questions.examRequests,
  openedEncountersBroadcastChannel: null,
  subjectiveAdditionalInformationId: questions.subjetiveAdditionalInformation,
  tabId: Math.random().toString(16).slice(2),
  timerRefreshEncounter: null,
  treatmentsId: questions.treatments,
  weight: '',
  suggestedExams: [],
  orderExamAttachments: [],
  prescriptionCategories: {
    certificate: 'ATESTADO',
    exam: 'EXAME',
    medication: 'RECEITA',
    referral: 'ENCAMINHAMENTO',
    report: 'RELATORIO',
  },
  validationErrorOnEncounter: {},
  medications: [],
  justificationForEmptyRequiredExams: null,
  shouldShowDuplicatedEncounterWarningModal: false,
  openEncounters: [],
  showNewCareLinePatientModalAfterEncounter: false,
}

const getSuggestedExamLabel = (examData) => {
  const isExamGroup = !isNil(path(['examGroupId'], examData))
  const examType = isExamGroup ? 'examGroup' : 'exam'
  const label = path([examType, 'name'], examData)

  if (!label) return null

  return label.toLowerCase().trim()
}

const getPatientExamDataTag = (suggestedExam, patient) => {
  const { careLineTagsPatient: patientTags } = patient
  const { careLineExamTagOptions: examTags } = suggestedExam

  const matchedPatientTag = find(
    examTag => any(
      patientTag => equals(
        patientTag.careLineTagOption?.id,
        examTag.careLineTagOptionId,
      ),
      patientTags,
    ),
  )(examTags)

  return matchedPatientTag || suggestedExam.defaultExamInterval
}

const getSuggestedExamInterval = (suggestedExam, patient) => {
  const patientTags = patient?.careLineTagsPatient

  if (isNilOrEmpty(patientTags)) {
    return suggestedExam.defaultExamInterval
  }

  const examDataTag = getPatientExamDataTag(suggestedExam, patient)

  return examDataTag
    ? examDataTag.examTagInterval
    : suggestedExam.defaultExamInterval
}

const getSuggestedExamRequiredDate = (suggestedExam, patient) => {
  const examInterval = getSuggestedExamInterval(suggestedExam, patient)

  const examResults = path(['examResults'], suggestedExam)
  const hasExamResult = !isNilOrEmpty(examResults)

  if (!hasExamResult) return moment().startOf('day')

  const lastExamDate = moment(path(['date'], examResults)).utc()
  return lastExamDate.clone().add(examInterval, 'days').startOf('day')
}

const mutations = {
  RESET_CHARTS_URI(state) {
    state.chartsURI = []
  },
  SET_HEIGHT(state, value) {
    state.height = value
  },
  SET_MEDICATIONS(state, value) {
    state.medications = value
  },
  SET_WEIGHT(state, value) {
    state.weight = value
  },
  CLEAR_TIMER(state) {
    clearTimeout(state.timerRefreshEncounter)
  },
  SET_ENCOUNTER_APPOINTMENT_OPTIONS(state, {
    id: appointmentId,
    patientId,
    clinicId,
    specialityId,
    professional,
    immediateCareId,
    professionalId,
  }) {
    if (appointmentId) {
      state.encounterAppointmentOptions = {
        patientId,
        clinicId,
        specialityId,
        appointmentId,
        professionalId: professional.id,
      }
    }
    if (immediateCareId) {
      state.encounterAppointmentOptions = {
        immediateCareId,
        patientId,
        specialityId,
        professionalId,
      }
    }
    localStorage.setItem('_encounterOptions', JSON.stringify(state.encounterAppointmentOptions))
  },
  CLEAR_ENCOUNTER_APPOINTMENT_OPTIONS(state) {
    state.encounterAppointmentOptions = {}
    localStorage.removeItem('_encounterOptions')
  },
  SET_ENCOUNTER_RESPONSE(state, { questionId, res }) {
    if (isNil(state.encounter.data)) state.encounter.data = {}
    state.encounter.data[questionId] = res
  },
  SET_EXPIRED_PROBLEMS_LIST(state, setExpiredProblemsList) {
    state.encounter.expiredActiveProblems = setExpiredProblemsList
  },
  SET_ACTIVE_PROBLEMS(state, activeProblems) {
    state.encounter.activeProblems = activeProblems
  },
  SET_ENCOUNTERS(state, encounters) {
    state.encounters = encounters
  },
  SET_ENCOUNTERS_FILTERS(state, filters) {
    state.encountersFilters = filters
  },
  SET_ENCOUNTERS_PAGINATION(state, encountersPagination) {
    state.encountersPagination = {
      page: encountersPagination.page,
      isDesc: encountersPagination.descending,
    }
  },
  SET_ALL_ENCOUNTERS(state, encounters) {
    state.allEncounters = encounters
  },
  SET_PATIENT(state, patient) {
    state.patient = patient
  },
  SET_PATIENT_ENCOUNTERS(state, encounters) {
    state.patient = {
      ...state.patient,
      encounters,
    }
  },
  SET_PATIENT_RESUME(state, resume) {
    state.patient.resume = resume
    state.reload += 1
  },
  SET_PATIENT_EXAM_RESULTS(state, examResults) {
    state.patient.examResults = examResults
  },
  SET_PATIENT_SCHEDULES(state, schedules) {
    state.patient.schedules = schedules
  },
  SET_ORDER_EXAM_ATTACHMENTS(state, orderExamAttachments) {
    state.orderExamAttachments = orderExamAttachments
  },
  CLEAR_PATIENT(state) {
    state.patient = {}
  },
  SET_PIN_ACTION(state, { action, condition, questionId }) {
    const conditionId = hasPath(['diseaseId'], condition) ? 'diseaseId' : 'surgeryId'
    const questionData = hasPath(['value'], state.encounter.data[questionId])
      ? path(['value'], state.encounter.data[questionId])
      : state.encounter.data[questionId]
    const conditionToApply = find(
      propEq(path([conditionId], condition), conditionId),
    )(questionData)

    if (isNil(conditionToApply)) return

    if (hasPath(['personalHighlightData'], conditionToApply)) {
      conditionToApply.personalHighlightData.isHighlighted = equals('pin', action)
    }
    if (hasPath(['familyHighlightData'], conditionToApply)) {
      conditionToApply.familyHighlightData.isHighlighted = equals('pin', action)
    }
    if (hasPath(['activeProblemHighlightData'], conditionToApply)) {
      conditionToApply.activeProblemHighlightData = {
        isHighlighted: equals('pin', action),
        displayOrder: 0,
      }
    }
  },
  SET_RECYCLED_CONDITION(state, condition) {
    state.recycledCondition = condition
  },
  SET_DEVOLVED_CONDITION(state, condition) {
    state.devolvedCondition = condition
  },
  SET_RECYCLED_CONDITION_TO_REMOVE(state, condition) {
    state.recycledConditionToRemove = condition
  },
  CLEAR_RECYCLED_CONDITION(state) {
    state.recycledCondition = null
  },
  CLEAR_DEVOLVED_CONDITION(state) {
    state.devolvedCondition = null
  },
  CLEAR_RECYCLED_CONDITION_TO_REMOVE(state) {
    state.recycledConditionToRemove = null
  },
  CLEAR_ENCOUNTER(state) {
    state.encounter = {}
    state.validationErrorOnEncounter = {}
  },
  SET_IS_NEW_ENCOUNTER(state, isNewEncounter) {
    state.isNewEncounter = isNewEncounter
  },
  SET_CHARTS_URI(state, chartsURI) {
    state.chartsURI.push(chartsURI)
  },
  SET_IS_LOADING_SPECIFIC_ENCOUNTER(state, value) {
    state.isLoadingSpecificEncounter = value
  },
  SET_ENCOUNTER_TIME(state, value) {
    state.encounterTime = value
  },
  SET_TIMER_REFRESH_ENCOUNTER(state, value) {
    state.timerRefreshEncounter = value
  },
  SET_ENCOUNTER(state, value) {
    state.encounter = value
  },
  SET_ENCOUNTER_PRESCRIPTION_MANAGEMENT(state, prescriptionData) {
    if (isNil(state.encounter.data.prescriptionsManagement)) {
      state.encounter.data.prescriptionsManagement = [prescriptionData]
    } else {
      state.encounter.data.prescriptionsManagement.push(prescriptionData)
    }
  },
  SET_ENCOUNTER_PRESCRIPTION_MANAGEMENT_STATE(
    state,
    {
      prescriptionId,
      prescriptionState,
      prescriptionError,
      processAttempts,
      rawData,
    },
  ) {
    const prescriptions = state.encounter.data.prescriptionsManagement
    const prescriptionIndex = findIndex(propEq(prescriptionId, 'id'))(prescriptions)

    if (prescriptionIndex >= 0) {
      const serializedError = {
        message: prescriptionError?.message,
        stack: prescriptionError?.stack,
      }

      prescriptions[prescriptionIndex] = {
        ...prescriptions[prescriptionIndex],
        state: prescriptionState,
        processAttempts,
        error: prescriptionError ? serializedError : null,
        rawData,
      }
    }
  },
  SET_ENCOUNTER_PRESCRIPTION_MANAGEMENT_MEDICAL_REPORTS(state, {
    prescriptionId,
    medicalReportId,
    medicalReportType,
    medicalReportState,
  }) {
    const prescription = find(
      propEq(prescriptionId, 'id'),
    )(state.encounter.data.prescriptionsManagement)

    const medicalReportData = {
      id: medicalReportId,
      type: medicalReportType,
      state: medicalReportState,
    }

    if (prescription) {
      if (isNil(prescription.medicalReport)) {
        prescription.medicalReports = [medicalReportData]
      } else {
        prescription.medicalReports.push(medicalReportData)
      }
    }
  },
  SET_SUGGESTED_EXAMS(state, { suggestedExams }) {
    const examsAvailableToPatient = filter(
      exam => getPatientExamDataTag(exam, state.patient),
      suggestedExams,
    )

    const data = map(
      (exam) => {
        const currentDate = moment().utc().startOf('day')
        const requiredDate = getSuggestedExamRequiredDate(exam, state.patient)

        const daysLeftToRequired = requiredDate.diff(currentDate, 'days')
        const isRequiredExam = (daysLeftToRequired === null || daysLeftToRequired <= 0)

        const label = getSuggestedExamLabel(exam)

        return {
          ...exam,
          label,
          daysLeftToRequired,
          requiredDate: requiredDate.format('DD/MM/YYYY'),
          isRequired: isRequiredExam,
        }
      },
      examsAvailableToPatient,
    )

    state.suggestedExams = data
  },
  SET_QUESTION_RESPONSE(state, { questionId, res }) {
    state.encounter.data[questionId] = res
  },
  SET_VALIDATION_ERROR_ON_ENCOUNTER(state, field) {
    state.validationErrorOnEncounter = {
      ...state.validationErrorOnEncounter,
      ...field,
    }
  },
  SET_ENCOUNTER_FORM_TOUCHED(state, status) {
    state.encounterFormTouched = status
  },
  SET_JUSTIFICATION_FOR_EMPTY_REQUIRED_EXAMS(state, value) {
    state.justificationForEmptyRequiredExams = value
  },
  SET_OPENED_ENCOUNTERS_BROADCAST_CHANNEL(state, value) {
    state.openedEncountersBroadcastChannel = value
  },
  SET_SHOULD_SHOW_DUPLICATED_ENCOUNTER_WARNING_MODAL(state, value) {
    state.shouldShowDuplicatedEncounterWarningModal = value
  },
  SET_OPEN_ENCOUNTERS(state, value) {
    state.openEncounters = value
  },
  SET_ATTACHMENTS(state, value) {
    state.attachments = value
    state.reload += 1
  },
  SET_SHOW_NEW_CARE_LINE_PATIENT_MODAL_AFTER_ENCOUNTER(state, value) {
    state.showNewCareLinePatientModalAfterEncounter = value
  },
}

const actions = {
  clearHeightAndWeight({ commit }) {
    commit('SET_HEIGHT', '')
    commit('SET_WEIGHT', '')
  },
  addMedications({ state, commit }, { newMedications }) {
    commit('SET_MEDICATIONS', [...state.medications, ...newMedications])
  },
  deleteMedication({ state, commit }, { medicationId }) {
    const medIndex = findIndex(propEq(medicationId, 'id'))(state.medications)
    const medications = clone(state.medications)

    if (medIndex < 0) return

    medications.splice(medIndex, 1)

    commit('SET_MEDICATIONS', medications)
  },
  clearMedication({ commit }) {
    commit('SET_MEDICATIONS', [])
  },
  addResponseListToQuestion({ state, commit }, { questionId, res, hasConditional }) {
    const currentRes = hasConditional
      ? path(['encounter', 'data', questionId, 'value'], state)
      : path(['encounter', 'data', questionId], state)

    if (isNilOrEmpty(currentRes)) {
      commit(
        'SET_QUESTION_RESPONSE',
        {
          questionId,
          res: hasConditional
            ? {
              value: res,
              conditionalValue: true,
            }
            : res,
        },
      )
      return
    }

    const idType = cond([
      [prop('specialityId'), always('specialityId')],
      [prop('examId'), always('examId')],
      [T, always('id')],
    ])(currentRes[0])

    const currentResIds = pluck(idType, currentRes)

    const filteredRes = res.filter(item => !includes(item[idType], currentResIds))

    const updatedRes = currentRes.map((item) => {
      const existentRes = res.find(resItem => resItem[idType] === item[idType])
      if (existentRes) return existentRes
      return item
    })

    const newRes = concat(updatedRes, filteredRes)

    const formattedRes = hasConditional
      ? {
        value: newRes,
        conditionalValue: true,
      }
      : newRes

    commit('SET_QUESTION_RESPONSE', { questionId, res: formattedRes })
  },
  async getEncounterTime({ commit, state }) {
    const { encounter } = state
    const diffTime = moment().diff(
      moment(encounter.date)
        .seconds('00')
        .subtract(3, 'hours'),
      'HH:mm',
    )
    commit('SET_ENCOUNTER_TIME', moment(diffTime).format('HH:mm'))
  },
  async getSpecificEncounter({ commit, dispatch }, id) {
    commit('SET_IS_LOADING_SPECIFIC_ENCOUNTER', true)
    return axios.get(`/emr/encounter/${id}`)
      .then((res) => {
        commit('SET_ENCOUNTER', res.data.encounter)
        dispatch('getEncounterTime')
      })
      .catch(err => err)
      .finally(() => {
        commit('SET_IS_LOADING_SPECIFIC_ENCOUNTER', false)
      })
  },
  async getLastOpenEncounter({ state, rootGetters }, encounterFormName) {
    const params = {
      encounterFormName,
      patientId: state.patient.id,
      professionalId: rootGetters['authentication/user'].professionalId,
    }
    const { data } = await axios.get('/emr/encounter/lastOpen', { params })

    return data.encounter
  },
  setEmrPatient({ commit, state }, patientId) {
    return axios.get(`patient/${patientId}`)
      .then((res) => {
        commit('SET_PATIENT', {
          ...state.patient,
          ...res.data.patient,
        })
      })
      .catch(err => err)
  },
  sendPrescriptionEmail({ state }, prescriptionId) {
    return axios.post(`/emr/patient_medical_resume/${state.patient.resume.id}/send_prescription_email`, { prescriptionId })
      .then(res => res)
      .catch(err => err)
  },
  getEncounterPrescriptionHistory(_, { patientId, page }) {
    return new Promise((resolve, rejectPromise) => {
      axios.get('/emr/encounter_prescription_history', {
        params: {
          patientId,
          page,
        },
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(() => {
          rejectPromise()
        })
    })
  },
  getEmrPaginateEncounters({ commit, state }, patientId) {
    const params = {
      patientId,
      ...state.encountersPagination,
      ...removeNilOrEmpty(state.encountersFilters),
      status: 'closed',
    }
    return axios.get('/emr/encounter', { params })
      .then((res) => {
        commit('SET_ENCOUNTERS', res.data.encounters)
      })
      .catch(err => err)
  },
  getAllEncounters({ commit }, patientId) {
    const params = {
      patientId,
    }
    return axios.get('/emr/encounter', { params })
      .then((res) => {
        commit('SET_ALL_ENCOUNTERS', res.data.encounters)
      })
      .catch(err => err)
  },
  getPatientResume({ commit }, patientId) {
    const params = { patientId }
    return axios.get('/emr/patient_medical_resume', { params })
      .then((res) => {
        commit('SET_PATIENT_RESUME', res.data.patientMedicalResume)
      })
  },
  getPatientExamResult({ commit }, patientId) {
    const params = { patientId }
    return axios.get('/emr/exam_result', { params })
      .then((res) => {
        commit('SET_PATIENT_EXAM_RESULTS', res.data.examResults)
      })
  },
  getSchedules({ commit }, patientId) {
    const params = { patientId }
    return axios.get('/emr/schedule', { params })
      .then((res) => {
        commit('SET_PATIENT_SCHEDULES', res.data.rows)
      })
  },
  async updateEncounter({ state, commit, dispatch }, attributes = {}) {
    const encounterId = router.history.current.params?.encounterId
      ? router.history.current.params?.encounterId
      : await dispatch('getLastOpenEncounter')?.id

    if (!encounterId) return

    const { type } = state.encounter
    const dataToUpdate = {
      data: isNil(state.encounter.data)
        ? {}
        : state.encounter.data,
    }
    if (type) dataToUpdate.type = type
    const { data } = await axios.patch(
      `/emr/encounter/${encounterId}`,
      {
        ...dataToUpdate,
        ...attributes,
      },
    )
    if (data) commit('SET_ENCOUNTER', data)
  },
  refreshEncounter({ commit, state, dispatch }) {
    commit('SET_TIMER_REFRESH_ENCOUNTER', setInterval(() => {
      const { encounterId } = router.history.current.params

      if (!isNil(encounterId)) {
        dispatch('updateEncounter')
        dispatch('getEncounterTime')
      } else {
        dispatch('clearTimer')
        dispatch('setIsNewEncounter', false)
      }
    }, state.refreshTime))
  },
  async setActiveHighlightData(_, {
    status,
    id,
    activeProblemHighlightData: current,
  }) {
    if (not(isNil(current))) {
      return {
        activeProblemHighlightData: current,
      }
    }
    if (not(equals(ENCOUNTER.hightlightConditionsStatus.resolved, status))) {
      try {
        const {
          data: {
            careCycle: {
              isHighlighted,
              highlightExpirationTimeByDays,
              displayOrder,
            },
          },
        } = await axios.get(`emr/care_cycle/disease/${id}`)

        const activeProblemHighlightData = {
          isHighlighted,
          highlightExpirationTimeByDays,
          displayOrder,
        }

        return {
          careCycle: activeProblemHighlightData,
          activeProblemHighlightData,
        }
      } catch (err) {
        return { activeProblemHighlightData: null }
      }
    }

    return { activeProblemHighlightData: null }
  },
  verifyQuestionRequired({ state, dispatch }, { question, parameters }) {
    const requiredFunction = getRequiredFunction(question)
    if (isNil(requiredFunction)) return null

    const requiredMessage = requiredFunction({
      ...parameters,
      encounterData: state.encounter.data,
      question,
    })
    if (requiredMessage) {
      dispatch('setValidationErrorOnEncounter', { [question.id]: true })
      return requiredMessage
    }
    dispatch('setValidationErrorOnEncounter', { [question.id]: false })
    return null
  },
  async getCareLineSuggestedExams({ commit, state, rootState }, { patientId }) {
    try {
      const params = { patientId }
      const res = await axios.get('/emr/care_line_suggested_exam', { params })

      const suggestedExamsAllowedSpecialities = [
        SPECIALITY.familyCommunityHealth,
        SPECIALITY.familyNurse,
        SPECIALITY.nursingTechnician,
      ]

      const userSpecialities = path(['user', 'professional', 'specialities'], rootState.authentication)
      const userSpecialitiesIds = pluck('id', userSpecialities)

      const currentSpecialities = path(['encounter', 'id'], state)
        ? [state.encounter.specialityId]
        : userSpecialitiesIds

      const shouldSeeSuggestedExam = any(
        speciality => includes(
          speciality,
          suggestedExamsAllowedSpecialities,
        ),
      )(currentSpecialities)

      const suggestedExams = path(['data', 'suggestedExams'], res)
      if (suggestedExams && shouldSeeSuggestedExam) {
        commit('SET_SUGGESTED_EXAMS', { suggestedExams })
        return state.suggestedExams
      }
      commit('SET_SUGGESTED_EXAMS', { suggestedExams: [] })
      return []
    } catch (error) {
      return []
    }
  },
  setExpiredActiveProblems({ commit }, expiredProblemsList) {
    commit('SET_EXPIRED_PROBLEMS_LIST', expiredProblemsList)
  },
  setEncounterAppointmentOptions({ commit }, appointment) {
    commit('SET_ENCOUNTER_APPOINTMENT_OPTIONS', appointment)
  },
  setPinAction({ commit }, data) {
    commit('SET_PIN_ACTION', data)
  },
  setEncountersFilters({ commit }, filters) {
    commit('SET_ENCOUNTERS_FILTERS', filters)
  },
  setEncountersPagination({ commit }, pagination) {
    commit('SET_ENCOUNTERS_PAGINATION', pagination)
  },
  setEncounterResponse({ commit, dispatch }, response) {
    commit('SET_ENCOUNTER_RESPONSE', response)
    dispatch('updateEncounter')
  },
  clearEncounter({ commit }) {
    commit('CLEAR_ENCOUNTER')
    commit('SET_JUSTIFICATION_FOR_EMPTY_REQUIRED_EXAMS', null)
  },
  clearEncounterAppointmentOptions({ commit }) {
    commit('CLEAR_ENCOUNTER_APPOINTMENT_OPTIONS')
  },
  resetChartsURI({ commit }) {
    commit('RESET_CHARTS_URI')
  },
  clearTimer({ commit }) {
    commit('CLEAR_TIMER')
  },
  clearDevolvedCondition({ commit }) {
    commit('CLEAR_DEVOLVED_CONDITION')
  },
  clearRecycledConditionToRemove({ commit }) {
    commit('CLEAR_RECYCLED_CONDITION_TO_REMOVE')
  },
  setRecycledCondition({ commit }, condition) {
    commit('SET_RECYCLED_CONDITION', condition)
  },
  clearRecycledCondition({ commit }) {
    commit('CLEAR_RECYCLED_CONDITION')
  },
  setRecycledConditionToRemove({ commit }, condition) {
    commit('SET_RECYCLED_CONDITION_TO_REMOVE', condition)
  },
  setDevolvedCondition({ commit }, condition) {
    commit('SET_DEVOLVED_CONDITION', condition)
  },
  setIsNewEncounter({ commit }, isNewEncounter) {
    commit('SET_IS_NEW_ENCOUNTER', isNewEncounter)
  },
  setHeight({ commit }, height) {
    commit('SET_HEIGHT', height)
  },
  setMedication({ commit }, medications) {
    commit('SET_MEDICATIONS', medications)
  },
  setWeight({ commit }, weight) {
    commit('SET_WEIGHT', weight)
  },
  setChartsURI({ commit }, chartsURI) {
    commit('SET_CHARTS_URI', chartsURI)
  },
  setValidationErrorOnEncounter({ commit }, validation) {
    commit('SET_VALIDATION_ERROR_ON_ENCOUNTER', validation)
  },
  setEncounterFormTouched({ commit }, status) {
    commit('SET_ENCOUNTER_FORM_TOUCHED', status)
  },
  getCarePlan(_, patientId) {
    const params = { patientIds: [patientId] }
    return axios.get('/patient/care_plan', { params })
      .then(({ data }) => data)
  },
  async sendCarePlanLink(_, patientId) {
    return new Promise((resolve, rejectPromise) => {
      axios.post(`/patient/${patientId}/send_care_plan_link`)
        .then(({ data }) => resolve(data))
        .catch(rejectPromise)
    })
  },
  createPrescription(_, { encounterId, prescriptionLogoUrl }) {
    return axios
      .post('emr/prescription', {
        encounterId,
        logoUrl: prescriptionLogoUrl,
      })
      .then(({ data }) => data)
  },
  getExistingPrescription(_, prescriptionId) {
    return axios.get(`emr/prescription/${prescriptionId}`).then(({ data }) => data)
  },
  updatePrescriptionState(
    { commit, dispatch },
    {
      prescriptionId,
      prescriptionState,
      prescriptionError,
      processAttempts,
      rawData,
    },
  ) {
    const prescriptionData = {
      prescriptionId,
      prescriptionState,
      prescriptionError,
      processAttempts,
      rawData,
    }
    commit('SET_ENCOUNTER_PRESCRIPTION_MANAGEMENT_STATE', prescriptionData)
    return dispatch('updateEncounter')
  },
  initPrescriptionProgress(
    { commit, dispatch },
    {
      idPrescricao,
      CodigoValidacao,
      Token,
      Referencia,
      prescriptionLabel,
    },
  ) {
    const prescriptionData = {
      id: idPrescricao,
      token: CodigoValidacao,
      state: 'in progress',
      bearerToken: Token,
      referenceId: Referencia,
      prescriptionLabel,
    }
    commit('SET_ENCOUNTER_PRESCRIPTION_MANAGEMENT', prescriptionData)
    return dispatch('updateEncounter')
  },
  savePrescriptionFile(_, {
    encounterId,
    prescriptionId,
    fileData,
  }) {
    const params = {
      encounterId,
      prescriptionId,
      fileData: {
        ...fileData,
        date: moment(fileData.date).format('YYYY-MM-DD'),
      },
    }
    return axios.post('emr/prescription/save_file', params).then(({ data }) => data)
  },
  getPrescriptionToDownload(_, {
    encounterId,
    prescriptionId,
    fileData,
  }) {
    const fileDataStringObject = JSON.stringify({
      ...fileData,
      date: moment(fileData.date).format('YYYY-MM-DD'),
    })
    const params = {
      encounterId,
      prescriptionId,
      fileData: fileDataStringObject,
    }
    return axios.get('/emr/prescription/get_file', { params }).then(({ data }) => data)
  },
  createPrescriptionReport(_, {
    prescriptionId,
    reportData,
    bearerToken,
  }) {
    const body = {
      reportData,
      bearerToken,
    }
    return axios.post(`/emr/prescription/${prescriptionId}/report`, body).then(({ data }) => data)
  },
  updatePrescriptionReport(_, {
    prescriptionId,
    reportId,
    reportData,
    bearerToken,
  }) {
    const body = {
      reportData,
      bearerToken,
    }
    return axios.put(`/emr/prescription/${prescriptionId}/report/${reportId}`, body).then(({ data }) => data)
  },
  updatePrescriptionManagementMedicalReports({ commit }, {
    prescriptionId,
    medicalReportId,
    medicalReportType,
    medicalReportState,
  }) {
    commit('SET_ENCOUNTER_PRESCRIPTION_MANAGEMENT_MEDICAL_REPORTS', {
      prescriptionId,
      medicalReportId,
      medicalReportType,
      medicalReportState,
    })
  },
  createExamRequest(_, { prescriptionId, examName }) {
    const body = { examName }
    return axios.post(`/emr/prescription/${prescriptionId}/exam`, body).then(({ data }) => data)
  },
  getOrderExamAttachment({ commit }, params) {
    return new Promise((resolve, rejectPromise) => {
      axios.get('/emr/order_exam_attachment', { params })
        .then((res) => {
          commit('SET_ORDER_EXAM_ATTACHMENTS', res.data.orderExamAttachments)
          resolve(res.data.orderExamAttachments)
        })
        .catch(rejectPromise)
    })
  },
  updateOrderExamAttachment(_, { id, body }) {
    return new Promise((resolve, rejectPromise) => {
      axios.patch(`/emr/order_exam_attachment/${id}`, body)
        .then(res => resolve(res))
        .catch(rejectPromise)
    })
  },
  getPatientCareLineExams(_, { id, filters }) {
    return new Promise((resolve, rejectPromise) => {
      axios.get(`/patient/${id}/care_line_exams`, { params: filters })
        .then((res) => {
          resolve(res.data)
        })
        .catch(rejectPromise)
    })
  },
  async createAttachment({ dispatch }, {
    file,
    patientId,
    type,
    category,
  }) {
    const params = {
      patientId,
      type,
      category,
    }
    const res = await axios.post('/emr/attachment', file, { params }).then(({ data }) => data)
    dispatch('listAttachments', { patientId })
    return res
  },
  getAttachment(_, params) {
    return new Promise((resolve, rejectPromise) => {
      axios.get('/emr/attachment', { params })
        .then(res => resolve(res.data))
        .catch(rejectPromise)
    })
  },
  listAttachments({ commit }, params) {
    return new Promise((resolve, rejectPromise) => {
      axios.get('/emr/attachment/list', { params })
        .then((res) => {
          commit('SET_ATTACHMENTS', res.data.attachments)
          resolve(res.data.attachments)
        })
        .catch(rejectPromise)
    })
  },
  createExamResults(_, params) {
    return new Promise((resolve, rejectPromise) => {
      axios.post('/emr/exam_result', params)
        .then(res => resolve(res))
        .catch(rejectPromise)
    })
  },
  setJustificationForEmptyRequiredExams({ commit }, justification) {
    commit('SET_JUSTIFICATION_FOR_EMPTY_REQUIRED_EXAMS', justification)
  },
  getPrescriptionByReference(_, { bearerToken, referenceId, prescriptionId }) {
    return new Promise((resolve, rejectPromise) => {
      axios.get(`/emr/prescription/reference/${referenceId}`, {
        params: {
          bearerToken,
          prescriptionId,
        },
      })
        .then(res => resolve(res.data))
        .catch(rejectPromise)
    })
  },
  deletePrescription(_, { bearerToken, prescriptionId }) {
    return new Promise((resolve, rejectPromise) => {
      axios.delete(`/emr/prescription/${prescriptionId}`, {
        params: { bearerToken },
      })
        .then(res => resolve(res.data))
        .catch(rejectPromise)
    })
  },
  setShouldShowDuplicatedEncounterWarningModal({ commit }, value) {
    commit('SET_SHOULD_SHOW_DUPLICATED_ENCOUNTER_WARNING_MODAL', value)
  },
  async setOpenedEncountersBroadcastChannel({ commit, state, dispatch }, redirectFunction) {
    await commit('SET_OPENED_ENCOUNTERS_BROADCAST_CHANNEL', new BroadcastChannel('tab-activity'))

    const { encounterId } = router.history.current.params

    state.openedEncountersBroadcastChannel.addEventListener('message', (event) => {
      if (isNil(router.history?.current?.params?.encounterId)) {
        state.openedEncountersBroadcastChannel.close()
        return
      }

      if (
        equals(encounterId, event.data.encounterId)
        && !equals(state.tabId, event.data.tabId)
      ) {
        redirectFunction()
        state.openedEncountersBroadcastChannel.close()
        dispatch('setShouldShowDuplicatedEncounterWarningModal', true)
      }
    })

    state.openedEncountersBroadcastChannel.postMessage({
      encounterId,
      tabId: state.tabId,
    })
  },
  getOpenEncounters({ commit }) {
    return axios.get('/emr/encounter/open')
      .then((res) => {
        commit('SET_OPEN_ENCOUNTERS', res.data.encounters)
      })
      .catch(err => err)
  },
  setShowNewCareLinePatientModalAfterEncounter({ commit, state }, value) {
    if (equals(false, value)) {
      return commit('SET_SHOW_NEW_CARE_LINE_PATIENT_MODAL_AFTER_ENCOUNTER', false)
    }

    const {
      careLinesPatient,
      healthMaintenanceOrganization,
    } = state.patient

    const patientHmoPackage = healthMaintenanceOrganization.packageType

    if (equals(HMO.package.none.value, patientHmoPackage)) {
      return commit('SET_SHOW_NEW_CARE_LINE_PATIENT_MODAL_AFTER_ENCOUNTER', false)
    }

    const careLinesPatientWithoutActivationJourney = reject(
      pathEq(CARE_LINE.activationJourney, ['careLine', 'id']),
      careLinesPatient,
    )
    const patientHasCareLines = careLinesPatientWithoutActivationJourney.length > 0

    return commit(
      'SET_SHOW_NEW_CARE_LINE_PATIENT_MODAL_AFTER_ENCOUNTER',
      !patientHasCareLines,
    )
  },
}

const getters = {
  allEncounters: state => state.allEncounters,
  chartsURI: state => state.chartsURI,
  devolvedCondition: state => state.devolvedCondition,
  emrAllowedProfessions: state => state.emrAllowedProfessions,
  encounter: state => state.encounter,
  encounterAppointmentOptions: state => state.encounterAppointmentOptions,
  encounterReasonId: state => state.encounterReasonId,
  encounters: state => state.encounters,
  encounterTime: state => state.encounterTime,
  height: state => state.height,
  isLoadingSpecificEncounter: state => state.isLoadingSpecificEncounter,
  isNewEncounter: state => state.isNewEncounter,
  medicalReferralId: state => state.medicalReferralId,
  NotFoundMessage: state => state.NotFoundMessage,
  orderExamAttachments: state => state.orderExamAttachments,
  patient: state => state.patient,
  patientCareLines: state => state.patient.careLinesPatient,
  questions: state => state.questions,
  recycledCondition: state => state.recycledCondition,
  recycledConditionToRemove: state => state.recycledConditionToRemove,
  reload: state => state.reload,
  reminderEncounterId: state => state.reminderEncounterId,
  requestExamsId: state => state.requestExamsId,
  suggestedExamsData: (state) => {
    const groupedExams = groupBy(
      ({ examId, examGroupId }) => examId || examGroupId, state.suggestedExams,
    )

    const uniqueExamsWithSmallestInterval = map(
      group => reduce(minBy(prop('defaultExamInterval')), group[0], group),
      groupedExams,
    )
    const uniqueSuggestedExams = values(uniqueExamsWithSmallestInterval)

    return sort(
      ascend(item => moment(item.requiredDate, 'DD/MM/YYYY').toDate()),
    )(uniqueSuggestedExams)
  },
  weight: state => state.weight,
  prescriptionCategories: state => state.prescriptionCategories,
  validationErrorOnEncounter: state => state.validationErrorOnEncounter,
  hasValidationErrorOnEncounter: state => includes(true, values(state.validationErrorOnEncounter)),
  encounterFormTouched: state => state.encounterFormTouched,
  medications: state => state.medications,
  shouldShowDuplicatedEncounterWarningModal:
    state => state.shouldShowDuplicatedEncounterWarningModal,
  openEncounters: state => state.openEncounters,
  attachments: state => state.attachments,
  isPatientHmoPackageTypeNone: state => state
    ?.patient
    ?.healthMaintenanceOrganization
    ?.packageType === HMO.package.none.value,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
