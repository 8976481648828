<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
      <div class="tw-grid tw-gap-4 tw-grid-cols-3">
        <v-text-field
          v-model="filters.name"
          filled
          label="Buscar por nome"
          clearable
          no-data-text="Digite para buscar"
          hide-details
        />
        <v-autocomplete
          v-model="filters.type"
          :items="communicationTypes"
          label="Tipo"
          item-text="label"
          item-value="value"
          :no-data-text="notFoundMessage"
          clearable
          filled
          hide-details
        />
        <v-btn
          class="btn-filter--height"
          color="dark-green"
          dark
          block
          large
          @click="searchCommunications"
        >
          <v-icon left>
            mdi-magnify
          </v-icon>
          <span class="tw-font-bold">
            Buscar Comunicado
          </span>
        </v-btn>
      </div>
    </div>

    <div class="tw-px-12 tw-mt-4">
      <div class="tw-flex tw-justify-center tw-mb-4">
        <v-btn
          class="tw-w-1/3"
          color="primary"
          large
          @click="showEditorTypeSelectorDialog = true"
        >
          <span class="tw-font-bold">
            Nova Comunicação
          </span>
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="communications"
        hide-default-footer
        disable-pagination
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
      >
        <template v-slot:item="communication">
          <tr>
            <td>{{ communication.item.name }}</td>
            <td>{{ communication.item.emailTitle }}</td>
            <td>{{ showCommunicationTypeValue(communication.item.type) }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="tw-mr-3"
                    text
                    icon
                    @click="editDialog(communication.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    @click="deleteDialog(communication.item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <editor-type-selector
      v-if="showEditorTypeSelectorDialog"
      :show-dialog="showEditorTypeSelectorDialog"
      @receiveEditorType="receiveEditorType"
      @closeEditorTypeSelectorDialog="showEditorTypeSelectorDialog = false"
    />

    <create-communication
      v-if="showCreateCommunicationDialog"
      :show-dialog="showCreateCommunicationDialog"
      :editor-type="editorTypeSelected"
      @closeDialog="showCreateCommunicationDialog = false"
    />

    <create-communication
      v-if="showEditCommunicationDialog"
      :show-dialog="showEditCommunicationDialog"
      edit-communication
      :communication-data="editedCommunication"
      :editor-type="editedCommunication.editorType"
      @closeDialog="closeEditDialog"
    />

    <v-dialog
      v-model="showDeleteCommunicationDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-text class="!tw-p-4 !tw-m-0">
          <div class="tw-text-base tw-font-bold tw-mt-2">
            Deseja realmente excluir esse comunicado?
          </div>
        </v-card-text>
        <v-card-actions class="!tw-p-4 tw-m-0">
          <div class="tw-w-full tw-flex tw-justify-between">
            <v-btn
              color="green darken-1"
              text
              large
              @click="exitDeleteDialog"
            >
              <span class="tw-font-semibold">
                Cancelar
              </span>
            </v-btn>
            <v-btn
              large
              color="error"
              @click="handleDeleteCommunication"
            >
              <span class="tw-font-semibold">
                Remover
              </span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
  cond,
  T,
  always,
  values,
  find,
  propEq,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import { COMMUNICATION } from 'amparo-enums'

export default {
  name: 'CommunicationList',
  components: {
    CreateCommunication: () => import('./CreateCommunication'),
    EditorTypeSelector: () => import('./EditorTypeSelector'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      editedCommunication: {},
      filters: {},
      headers: [
        {
          text: 'Nome',
          value: 'name',
          width: '40%',
        },
        {
          text: 'Título',
          value: 'emailTitle',
          width: '30%',
        },
        {
          text: 'Tipo',
          value: 'type',
          width: '20%',
        },
        {
          text: 'Ações',
          value: 'actions',
          width: '10%',
          sortable: false,
        },
      ],
      showCreateCommunicationDialog: false,
      showEditCommunicationDialog: false,
      showDeleteCommunicationDialog: false,
      showEditorTypeSelectorDialog: false,
      deletedCommunication: {},
      communicationTypes: values(COMMUNICATION.types),
      editorTypeSelected: '',
    }
  },
  computed: {
    ...mapGetters({
      communications: 'communication/communications',
    }),
    isCommunicationListEmpty() {
      return isEmpty(this.communications)
    },
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        await this.searchCommunications()
      },
    },
  },
  mounted() {
    this.listCommunication()
  },
  methods: {
    ...mapActions({
      setCommunicationFilters: 'communication/setCommunicationFilters',
      listCommunication: 'communication/listCommunication',
      deleteCommunication: 'communication/deleteCommunication',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    showCommunicationTypeValue(communicationType) {
      const communicationTypeValue = find(propEq(communicationType, 'value'), this.communicationTypes)
      return communicationTypeValue.label
    },
    async handleDeleteCommunication() {
      try {
        await this.deleteCommunication(this.deletedCommunication.id)
        this.buildSnackbar('success', 'Comunicado deletado com sucesso!')
        this.isSaving = false
      } catch ({ response }) {
        this.handleError(response)
      }
      this.deletedCommunication = {}
      this.showDeleteCommunicationDialog = false
      await this.listCommunication()
    },
    handleError(error) {
      const errorMessage = cond([
        [T, always('Erro interno')],
      ])(error.data.errorCode)

      this.buildSnackbar('error', errorMessage)
    },
    async setFilters() {
      await this.setCommunicationFilters(this.filters)
    },
    async searchCommunications() {
      await this.setFilters()
      await this.listCommunication()
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    receiveEditorType(editorType) {
      this.editorTypeSelected = editorType
      this.showEditorTypeSelectorDialog = false
      this.showCreateCommunicationDialog = true
    },
    editDialog(communicationData) {
      this.editedCommunication = communicationData
      this.showEditCommunicationDialog = true
    },
    closeEditDialog() {
      this.editedCommunication = {}
      this.showEditCommunicationDialog = false
    },
    deleteDialog(communication) {
      this.deletedCommunication = communication
      this.showDeleteCommunicationDialog = true
    },
    exitDeleteDialog() {
      this.deletedCommunication = {}
      this.showDeleteCommunicationDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.v-btn
  letter-spacing 1px

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.v-btn.v-btn--text
  border solid 1px thin-gray

.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px

.data-spacer
  height 20px
  width 100%

</style>
