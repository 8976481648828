<template>
  <v-dialog
    v-model="show"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title
        class="card-dialog__title font-weight-medium pb-0"
      >
        <v-icon
          class="mr-2"
          color="error"
        >
          mdi-alert
        </v-icon>
        <v-flex>
          Cancelar agendamento
        </v-flex>
        <v-spacer />
        <v-flex shrink>
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-card-text class="py-4 px-6 text-h6 dark-gray--text font-weight-light">
        <p class="cancel-immediate-dialog__subtitle">
          Porque deseja cancelar o atendimento?
        </p>
        <v-flex>
          <v-text-field
            v-model="cancellationReason"
            class="mb-3"
            label="Justificativa"
            clearable
            filled
            :error-messages="$v.cancellationReason.$error ? 'Digite o motivo do cancelamento' : ''"
            :error="$v.cancellationReason.$error"
            @blur="$v.cancellationReason.$touch()"
          />
        </v-flex>
      </v-card-text>
      <v-card-actions class="px-6 pb-4 pt-0">
        <v-spacer />
        <v-btn
          class="px-4 text-capitalize font-weight-lightbold"
          outlined
          @click="closeDialog()"
        >
          Voltar
        </v-btn>
        <v-btn
          class="ml-4 px-4 text-capitalize font-weight-lightbold"
          color="error"
          :loading="isLoadingCancel"
          @click="cancelAppointment()"
        >
          Cancelar agendamento
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { APPOINTMENT } from 'amparo-enums'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  emits: [
    'update:showDialog',
    'update:examCollectionUpdated',
  ],
  name: 'ExamCollectionCancelDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    examCollectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingCancel: false,
      cancellationReason: '',
    }
  },
  validations() {
    const cancelValidations = {
      cancellationReason: {
        required,
      },
    }
    return { ...cancelValidations }
  },
  computed: {
    show: {
      get() {
        return this.showDialog
      },
      set(value) {
        this.$emit('update:showDialog', value)
      },
    },
  },
  watch: {
    show() {
      this.resetForm()
    },
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      updateAppointment: 'agenda/updateAppointment',
    }),
    resetForm() {
      this.cancellationReason = ''
      this.$v.$reset()
    },
    closeDialog() {
      this.show = false
      this.cancellationReason = ''
    },
    buidlSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    async cancelAppointment() {
      this.$v.$touch()
      if (this.$v.$error) return

      this.isLoadingCancel = true
      try {
        await this.updateAppointment(
          {
            id: this.examCollectionData.appointment.id,
            attributes: {
              status: APPOINTMENT.status.unchecked.value,
              cancellationReason: this.cancellationReason,
            },
          },
        )

        this.buidlSnackbar('success', 'Agendamento cancelado com sucesso!')
        this.$emit('update:examCollectionUpdated')
        this.closeDialog()
      } catch (error) {
        this.buidlSnackbar('error', 'Erro ao cancelar agendamento')
      } finally {
        this.isLoadingCancel = false
      }
    },
  },
}
</script>
