<template>
  <div>
    <component-title
      :exam-collection="true"
    />
    <exam-collection-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import ExamCollectionListContainer from './ExamCollectionListContainer'

export default {
  name: 'ExamCollectionListPage',
  components: {
    ComponentTitle,
    ExamCollectionListContainer,
  },
}
</script>
