import axios from '@/lib/axios'
import {
  filter,
  isEmpty,
  not,
} from 'ramda'

const initialState = {
  loading: false,
  healthProducts: [],
}

const mutations = {
  SET_HEALTH_PRODUCTS(state, payload) {
    state.healthProducts = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  listHealthProduct({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      const params = filter(item => not(isEmpty(item)), filters)
      axios.get('/health_product', { params })
        .then(({ data }) => {
          commit('SET_HEALTH_PRODUCTS', data)
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  createHealthProduct({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.post('/health_product', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateHealthProduct({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.put(`/health_product/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  storeHealthProduct(_, id) {
    return new Promise((resolve, reject) => {
      axios.patch(`/health_product/store/${id}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  restoreHealthProduct(_, id) {
    return new Promise((resolve, reject) => {
      axios.patch(`/health_product/restore/${id}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

const getters = {
  healthProducts: state => state.healthProducts,
  loading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
