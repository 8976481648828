<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
      <div class="tw-grid tw-gap-4 md:tw-grid-cols-4">
        <v-text-field
          v-model="filters.identifier"
          filled
          label="Buscar por Código"
          clearable
          no-data-text="Digite para buscar"
          hide-details
        />
        <v-text-field
          v-model="filters.name"
          filled
          label="Buscar por Nome"
          clearable
          no-data-text
          hide-details
        />
        <v-autocomplete
          v-model="filters.type"
          label="Buscar por Tipo"
          clearable
          filled
          item-text="label"
          item-value="value"
          :items="careLineTypeItems"
          hide-details
        />
        <v-autocomplete
          v-model="filters.status"
          label="Buscar por Status"
          clearable
          filled
          :items="careLineStatusItems"
          item-text="label"
          item-value="value"
          hide-details
        />
      </div>
    </div>

    <div class="tw-px-12 tw-my-4">
      <div class="tw-flex tw-justify-center tw-mb-4">
        <v-btn
          class="tw-w-1/3"
          color="primary"
          large
          @click="showCreateCareLineDialog = true"
        >
          <span class="tw-font-semibold">
            Nova Linha de Cuidado
          </span>
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="restOfCareLines"
        hide-default-footer
        disable-pagination
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
        @change="listCareLine"
        @click:clear="listCareLine"
      >
        <template #item="{ item }">
          <care-line-list-item
            :care-line="item"
            @edit="editDialog"
            @disable="disableDialog"
            @reactivate="reactivateCareLine"
          />
        </template>
      </v-data-table>
      <v-expansion-panels
        v-model="panels"
        popout
        class="tw-mt-4"
      >
        <template v-for="[key, list] in careLinesByStatus">
          <v-expansion-panel
            v-if="itHas(list) && getPanelTitleBy(key)"
            :key="key"
            class="panel-header"
          >
            <v-expansion-panel-header>
              <h3>{{ getPanelTitleBy(key) }}</h3>
            </v-expansion-panel-header>
            <template #actions>
              <v-icon color="japaneseLaurel">
                $vuetify.icons.expand
              </v-icon>
            </template>
            <v-expansion-panel-content>
              <v-card>
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="list"
                    hide-default-footer
                    disable-pagination
                    no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
                  >
                    <template #item="{ item }">
                      <care-line-list-item
                        :care-line="item"
                        @edit="editDialog"
                        @disable="disableDialog"
                        @reactivate="reactivateCareLine"
                      />
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </div>

    <create-care-line
      v-if="showEditCareLineDialog"
      :show-dialog="showEditCareLineDialog"
      edit-care-line
      :care-line-data="editedCareLine"
      @closeDialog="closeEditDialog"
    />

    <create-care-line
      v-if="showCreateCareLineDialog"
      :show-dialog="showCreateCareLineDialog"
      @closeDialog="closeCreateDialog"
    />

    <v-dialog
      v-model="showDisableCareLineDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-text>
          <div class="tw-flex tw-flex-col tw-gap-y-2 tw-text-base tw-font-bold tw-mt-2">
            <div>
              Essa ação irá desativar a Linha de Cuidado
              e não permitirá atribuição da mesma a um diagnóstico.
            </div>
            <div>
              Deseja Continuar?
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-2">
            <v-btn
              color="japaneseLaurel"
              block
              text
              large
              @click="exitDisableDialog"
            >
              <span class="tw-font-semibold">
                Não
              </span>
            </v-btn>
            <v-btn
              large
              block
              color="error"
              @click="disableCareLine"
            >
              <span class="tw-font-semibold">
                Sim
              </span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  always,
  cond,
  descend,
  fromPairs,
  groupBy,
  isNil,
  not,
  prop,
  propEq,
  sort,
  T,
  toPairs,
  values,
} from 'ramda'
import { CARE_LINE } from 'amparo-enums'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CareLineList',
  components: {
    CreateCareLine: () => import('./CreateCareLine'),
    CareLineListItem: () => import('./CareLineListItem'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      showEditCareLineDialog: false,
      editedCareLine: {},
      disabledCareline: {},
      filters: {},
      listLoaded: true,
      count: '',
      numberOfPages: null,
      page: 1,
      panels: [true, true],
      headers: [
        {
          text: 'Código',
          value: 'identifier',
          width: '20%',
        },
        {
          text: 'Nome',
          value: 'name',
          width: '30%',
        },
        {
          text: 'Tipo',
          value: 'type',
          width: '20%',
        },
        {
          text: 'Status',
          value: 'status',
          width: '18%',
        },
        {
          text: 'Prioridade',
          value: 'isPrimary',
          width: '18%',
        },
        {
          text: 'Ações',
          value: 'action',
          width: '12%',
          sortable: false,
        },
      ],
      careLineTypeItems: values(CARE_LINE.types),
      careLineStatusItems: values(CARE_LINE.status),
      showCreateCareLineDialog: false,
      showDisableCareLineDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      careLineList: 'careLine/careLines',
    }),
    careLinesByStatus() {
      const groupedList = groupBy(
        cond([
          [propEq(CARE_LINE.status.model.value, 'status'), always('model')],
          [propEq(CARE_LINE.status.inactive.value, 'status'), always('inactive')],
          [T, always('rest')],
        ]),
        this.careLineList,
      )

      return sort(descend(prop(0)), toPairs(groupedList))
    },
    restOfCareLines() {
      return prop('rest', fromPairs(this.careLinesByStatus))
    },
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        await this.searchCareLine()
      },
    },
  },
  mounted() {
    this.listCareLine()
  },
  methods: {
    ...mapActions({
      listCareLine: 'careLine/listCareLine',
      updateCareLine: 'careLine/updateCareLine',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    itHas(list) {
      return not(isNil(list))
    },
    getPanelTitleBy(key) {
      const titles = {
        model: 'Linhas modelo',
        inactive: 'Linhas inativas',
      }

      return titles[key]
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    async disableCareLine() {
      try {
        await this.updateCareLine({
          careLineId: this.disabledCareline.id,
          careLineData: {
            status: 'inactive',
          },
        })
        this.buildSnackbar('success', 'Linha de Cuidado desativada com sucesso!')
        this.listCareLine()
      } catch ({ response }) {
        this.handleError(response)
      }
      this.disabledCareline = {}
      this.showDisableCareLineDialog = false
    },
    async reactivateCareLine(careLineData) {
      try {
        await this.updateCareLine({
          careLineId: careLineData.id,
          careLineData: {
            status: 'active',
          },
        })
        this.buildSnackbar('success', 'Linha de Cuidado reativada com sucesso!')
        this.listCareLine()
      } catch ({ response }) {
        this.handleError(response)
      }
    },
    handleError(error) {
      const errorMessage = cond([
        [T, always('Erro Interno')],
      ])(error.data.errorCode)
      this.buildSnackbar('error', errorMessage)
    },
    async searchCareLine() {
      this.listCareLine(this.filters)
    },
    editDialog(careLineData) {
      this.editedCareLine = careLineData
      this.showEditCareLineDialog = true
    },
    async closeEditDialog() {
      this.editedCareLine = {}
      this.showEditCareLineDialog = false
    },
    async closeCreateDialog() {
      this.showCreateCareLineDialog = false
    },
    disableDialog(careLine) {
      this.disabledCareline = careLine
      this.showDisableCareLineDialog = true
    },
    exitDisableDialog() {
      this.disabledCareline = {}
      this.showDisableCareLineDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.panel-header
  background-color rgba(light-gray, .6) !important
  color $japanese-laurel !important
</style>
