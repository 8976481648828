<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
      <div class="tw-grid tw-gap-4 md:tw-grid-cols-4">
        <v-text-field
          v-model="filters.title"
          filled
          label="Buscar por título"
          clearable
          no-data-text="Digite para buscar"
          hide-details
          @change="listFormResults"
          @click:clear="listFormResults"
        />
        <v-text-field
          v-model="filters.identifier"
          filled
          label="Buscar por código"
          clearable
          no-data-text="Digite para buscar"
          hide-details
          @change="listFormResults"
          @click:clear="listFormResults"
        />
        <v-autocomplete
          v-model="filters.categoryId"
          :items="categories"
          label="Categoria"
          item-text="name"
          item-value="id"
          :no-data-text="notFoundMessage"
          clearable
          filled
          hide-details
          @change="listFormResults"
          @click:clear="listFormResults"
        />
        <v-btn
          class="btn-filter--height"
          color="dark-green"
          large
          dark
          @click="listFormResults"
        >
          <v-icon left>
            mdi-magnify
          </v-icon>
          <span class="tw-font-semibold">
            Buscar Formulário
          </span>
        </v-btn>
      </div>
    </div>

    <div class="tw-px-12 tw-mt-4">
      <div
        class="tw-flex tw-justify-center tw-mb-4"
      >
        <v-btn
          class="tw-w-1/3"
          color="primary"
          large
          @click="showCreateFormDialog = true"
        >
          <span class="tw-font-bold">
            Novo Formulário
          </span>
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="forms"
        hide-default-footer
        disable-pagination
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
      >
        <template v-slot:item="form">
          <tr>
            <td>{{ form.item.title }}</td>
            <td>{{ form.item.identifier }}</td>
            <td>{{ form.item.category.name }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="tw-mr-3"
                    text
                    icon
                    @click="viewFormDialog(form.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Formulário</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="tw-mr-3"
                    text
                    icon
                    @click="listQuestionsDialog(form.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-format-list-checkbox</v-icon>
                  </v-btn>
                </template>
                <span>Editar Perguntas</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="tw-mr-3"
                    text
                    icon
                    @click="editDialog(form.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-account-edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="tw-mr-3"
                    text
                    icon
                    v-on="on"
                    @click="deleteDialog(form.item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <create-form
      v-if="showEditFormDialog"
      :show-dialog="showEditFormDialog"
      edit-form
      :form-data="editedForm"
      @closeDialog="closeEditDialog"
    />

    <create-form
      v-if="showCreateFormDialog"
      :show-dialog="showCreateFormDialog"
      @closeDialog="showCreateFormDialog = false"
    />

    <form-question-list
      v-if="showFormListQuestionDialog"
      :show-dialog="showFormListQuestionDialog"
      :form-id="editedForm.id"
      @closeDialog="showFormListQuestionDialog = false"
    />

    <v-dialog
      v-model="showViewFormDialog"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Visualizar Formulário
          <v-spacer />
          <v-btn
            icon
            @click="exitViewFormDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <form-builder
            v-if="showViewFormDialog"
            :form="viewForm"
            preview
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDeleteFormDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-text>
          <div class="tw-text-base tw-font-bold tw-pt-4">
            Deseja realmente excluir esse formulário?
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-2 tw-pb-2">
            <v-btn
              color="green darken-1"
              text
              large
              @click="exitDeleteDialog"
            >
              <span class="tw-font-semibold">
                Cancelar
              </span>
            </v-btn>
            <v-btn
              large
              color="error"
              @click="handleDeleteForm"
            >
              <span class="tw-font-semibold">
                Remover
              </span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
  isNil,
  reject,
  either,
  cond,
  T,
  always,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FormList',
  components: {
    CreateForm: () => import('./CreateForm'),
    FormQuestionList: () => import('./FormQuestionList'),
    FormBuilder: () => import('@/components/careFields/FormBuilder'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      showEditFormDialog: false,
      editedForm: {},
      filters: {},
      listLoaded: true,
      count: '',
      numberOfPages: null,
      page: 1,
      headers: [
        {
          text: 'Nome',
          value: 'title',
          width: '40%',
        },
        {
          text: 'Código',
          value: 'identifier',
          width: '20%',
        },
        {
          text: 'Categoria',
          value: 'category',
          width: '20%',
        },
        {
          text: 'Ações',
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ],
      showCreateFormDialog: false,
      showFormListQuestionDialog: false,
      showViewFormDialog: false,
      showDeleteFormDialog: false,
      deletedForm: {},
      viewForm: {},
    }
  },
  computed: {
    ...mapGetters({
      categories: 'careForm/categories',
      forms: 'careForm/forms',
    }),
    isFormListEmpty() {
      return isEmpty(this.forms)
    },
  },
  mounted() {
    this.listFormResults()
  },
  methods: {
    ...mapActions({
      listCategory: 'careForm/listCategory',
      listForm: 'careForm/listForm',
      deleteForm: 'careForm/deleteForm',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async handleDeleteForm() {
      try {
        await this.deleteForm(this.deletedForm.id)
        this.buildSnackbar('success', 'Formulário deletado com sucesso!')
        this.isSaving = false
      } catch ({ response }) {
        this.handleError(response)
      }
      this.deletedForm = {}
      this.showDeleteFormDialog = false
      await this.listForm()
    },
    handleError(error) {
      const errorMessage = cond([
        [T, always('Erro interno')],
      ])(error.data.errorCode)

      this.buildSnackbar('error', errorMessage)
    },
    listFormResults() {
      this.$nextTick(async () => {
        const params = reject(either(isNil, isEmpty), this.filters)
        try {
          await this.listForm(params)
          this.listLoaded = false
        } catch (e) {
          this.buildSnackbar('error', 'Erro Interno!')
        }
      })
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    editDialog(formData) {
      this.editedForm = formData
      this.showEditFormDialog = true
    },
    listQuestionsDialog(formData) {
      this.editedForm = formData
      this.showFormListQuestionDialog = true
    },
    closeEditDialog() {
      this.editedForm = {}
      this.showEditFormDialog = false
    },
    exitListQuestionsDialog() {
      this.editedForm = {}
      this.showEditFormDialog = false
    },
    viewFormDialog(form) {
      this.viewForm = form
      this.showViewFormDialog = true
    },
    exitViewFormDialog() {
      this.viewForm = {}
      this.showViewFormDialog = false
    },
    deleteDialog(form) {
      this.deletedForm = form
      this.showDeleteFormDialog = true
    },
    exitDeleteDialog() {
      this.deletedForm = {}
      this.showDeleteFormDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.v-btn.v-btn--text
  border solid 1px thin-gray

</style>
