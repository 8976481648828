<template>
  <v-app id="app">
    <v-system-bar
      v-if="isImpersonating"
      color="warning"
      class="!tw-z-50 print:tw-hidden white--text font-weight-medium justify-space-between"
      app
    >
      <div />
      <div>
        <v-icon color="white">
          mdi-alert-outline
        </v-icon>
        Você está impersonando um usuário ({{ impersonatedUserName }})
      </div>
      <v-btn
        color="white"
        text
        small
        @click="stopImpersonate()"
      >
        SAIR
      </v-btn>
    </v-system-bar>
    <app-header
      v-if="isUserInsideApplication && isUserLogged"
      class="not-printable-area"
      :impersonated-work-space="impersonatedWorkSpace"
      @toggleMenu="setIsMenuOpen"
      @openMenuSupport="setMenuSupportValue"
    />
    <side-menu
      v-if="isUserInsideApplication && isUserLogged"
      class="not-printable-area"
      :open="isMenuOpen"
      @close="setIsMenuOpen(false)"
    />
    <side-menu-support
      v-if="isUserInsideApplication && isUserLogged"
      :value="menuSupportValue"
      class="not-printable-area"
      @input="setMenuSupportValue"
    />
    <snackbar-alert />
    <app-content />
  </v-app>
</template>

<script>
import AppHeader from '@/components/Header/AppHeader'
import SideMenu from '@/components/Header/SideMenu'
import SideMenuSupport from '@/components/Header/SideMenuSupport'
import AppContent from '@/components/Header/AppContent'
import SnackbarAlert from '@/components/UI/SnackbarAlert'
import { mapActions, mapGetters } from 'vuex'
import {
  includes,
  isNil,
  not,
  path,
  prop,
} from 'ramda'

const showNeoAssistSupportPages = [
  'login',
  'IndexPage',
]

export default {
  components: {
    AppHeader,
    SideMenu,
    AppContent,
    SnackbarAlert,
    SideMenuSupport,
  },
  data() {
    return {
      isMenuOpen: false,
      menuSupportValue: false,
      impersonation: null,
    }
  },
  computed: {
    ...mapGetters({
      isAnExternalRoute: 'authentication/isAnExternalRoute',
      isUserLogged: 'authentication/isUserLogged',
    }),
    isImpersonating() {
      return !isNil(this.impersonation)
    },
    isUserInsideApplication() {
      return not(this.isAnExternalRoute(this.$route.path))
    },
    impersonatedUserName() {
      return path(['impersonatedUser', 'name'], this.impersonation)
    },
    impersonatedWorkSpace() {
      return prop('workSpace', this.impersonation)
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler({ name }) {
        this.getImpersonation().then((value) => {
          this.impersonation = value
        })
        if (includes(name, showNeoAssistSupportPages)) {
          this.loadNeoAssist()
          this.showNeoAssistChat()
          return
        }
        this.unloadNeoAssist()
      },
    },
  },
  created() {
    this.trySetDataOnReload()
  },
  methods: {
    ...mapActions({
      trySetDataOnReload: 'authentication/trySetDataOnReload',
      stopImpersonate: 'advanced/stopImpersonate',
      getImpersonation: 'advanced/getImpersonation',
    }),
    loadNeoAssist() {
      // eslint-disable-next-line
      window.NeoAssistTag = {}; NeoAssistTag.querystring = true; NeoAssistTag.pageid = ''; NeoAssistTag.clientdomain = 'amparosaude.neoassist.com'; NeoAssistTag.initialize = { }; var na = document.createElement('script'); na.type = 'text/javascript'; na.async = true; na.src = 'https://cdn.atendimen.to/n.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(na, s);
    },
    unloadNeoAssist() {
      const widget = document.querySelector('.neoasssist-widget-frame')
      if (not(isNil(widget))) widget.remove()
    },
    showNeoAssistChat() {
      const widget = document.querySelector('.neoasssist-widget-frame')
      if (not(isNil(widget))) widget.style.display = 'inline-block'
    },
    setIsMenuOpen(value) {
      this.isMenuOpen = value
    },
    setMenuSupportValue(value) {
      this.menuSupportValue = value
    },
  },
}
</script>

<style lang="stylus">
  @import 'style/_all.styl'
</style>
