<template>
  <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
    <div class="tw-grid tw-gap-4 md:tw-grid-cols-5">
      <v-select
        v-model="filters.version"
        label="Buscar por Tipo"
        :items="careCycleDiseaseTypes"
        clearable
        filled
        hide-details
        @change="emitFilters"
        @click:clear="emitFilters"
      />
      <v-text-field
        v-model="filters.code"
        filled
        label="Buscar por Código"
        clearable
        no-data-text
        hide-details
        @change="emitFilters"
        @click:clear="emitFilters"
      />
      <v-text-field
        v-model="filters.name"
        filled
        label="Buscar por Nome"
        clearable
        no-data-text="Digite para buscar"
        hide-details
        @change="emitFilters"
        @click:clear="emitFilters"
      />
      <v-select
        v-model="filters.highlightType"
        label="Buscar por Tipo de Destaque"
        item-text="label"
        item-value="value"
        :items="careCycleHightlightTypes"
        clearable
        filled
        hide-details
        @change="emitFilters"
        @click:clear="emitFilters"
      />
      <v-select
        v-model="filters.displayOrder"
        label="Buscar por Ordem"
        :items="careCycleDisplayOrder"
        clearable
        filled
        hide-details
        @change="emitFilters"
        @click:clear="emitFilters"
      />
    </div>
  </div>
</template>

<script>
import {
  converge,
  isEmpty,
  isNil,
  or,
  reject,
  values,
} from 'ramda'
import careCycleOptions from '@/enums/careCycle'

const { HIGHLIGHT_TYPE } = careCycleOptions

const { DISEASE_TYPE } = careCycleOptions

const { DISPLAY_ORDER } = careCycleOptions

export default {
  data() {
    return {
      filters: {
        page: 1,
      },
      careCycleHightlightTypes: values(HIGHLIGHT_TYPE),
      careCycleDiseaseTypes: values(DISEASE_TYPE),
      careCycleDisplayOrder: values(DISPLAY_ORDER),
    }
  },
  created() {
    this.emitFilters()
  },
  activated() {
    this.emitFilters()
  },
  methods: {
    emitFilters() {
      this.$nextTick(() => {
        this.$emit('filterUpdate', reject(converge(or, [isNil, isEmpty]), this.filters))
        return true
      })
    },
  },
}
</script>
