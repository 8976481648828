<template>
  <div>
    <component-title :last-breadcrumb="careLineName" />
    <div
      v-if="isLoading || showWrongCareLineMessage"
      class="tw-flex tw-justify-center tw-text-3xl tw-mt-5"
    >
      <div v-if="isLoading">
        <circular-loader />
      </div>
      <div v-else-if="showWrongCareLineMessage">
        Linha de cuidado inválida.
      </div>
    </div>
    <v-tabs
      v-else
      grow
      height="58"
      background-color="light-green"
      hide-slider
    >
      <v-tab active-class="color: primary">
        <span class="tw-text-white tw-font-bold">Atribuição automática de pacientes</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tw-text-white tw-font-bold">Fluxo de monitoramento</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tw-text-white tw-font-bold">Exames Vinculados</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tw-text-white tw-font-bold">Tags do paciente</span>
      </v-tab>
      <v-tab-item>
        <care-line-patients-assign />
      </v-tab-item>
      <v-tab-item>
        <care-line-action-list />
      </v-tab-item>
      <v-tab-item>
        <care-line-exams />
      </v-tab-item>
      <v-tab-item>
        <care-line-tags />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CircularLoader from '@/components/UI/CircularLoader'
import { mapActions, mapGetters } from 'vuex'
import CareLinePatientsAssign from './CareLinePatientsAssign'

export default {
  name: 'CareLineAssignsPage',
  components: {
    CareLineActionList: () => import('./CareLineAction/CareLineActionList'),
    CareLineTags: () => import('./CareLineTags'),
    CareLineExams: () => import('./CareLineExams'),
    ComponentTitle,
    CareLinePatientsAssign,
    CircularLoader,
  },
  data() {
    return {
      isLoading: false,
      showWrongCareLineMessage: false,
      careLineName: '',
    }
  },
  computed: {
    ...mapGetters({
      careLine: 'careLine/careLine',
    }),
  },
  mounted() {
    this.setCareLine()
  },
  methods: {
    ...mapActions({
      getCareLine: 'careLine/getCareLine',
      listCareLineAction: 'careLine/listCareLineAction',
      listCareLineDisease: 'careLine/listCareLineDisease',
      listCareLineAge: 'careLine/listCareLineAge',
    }),
    toggleIsLoading() {
      this.isLoading = !this.isLoading
    },
    setShowWrongCareLineMessage(show) {
      this.showWrongCareLineMessage = show
    },
    setCareLineName(value) {
      this.careLineName = value
    },
    async setCareLine() {
      this.toggleIsLoading()
      const { id } = this.$route.params
      try {
        await this.getCareLine(id)
        this.setPageOptions()
        await this.listCareLineDisease()
        await this.listCareLineAge()
        await this.listCareLineAction()
      } catch (error) {
        this.setShowWrongCareLineMessage(true)
      }
      this.toggleIsLoading()
    },
    setPageOptions() {
      const { name } = this.careLine
      this.setCareLineName(name)
      this.$route.meta.description = `Atribuições para a linha ${name}`
    },
  },
}
</script>
